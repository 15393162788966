import React, { useState, useEffect, useCallback } from "react";
import { Form, Button, Container } from "react-bootstrap";
import axios from "../../axiosConfig";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useAuth } from "../../context/AuthContext";
import "../../styles/Admin.css";

function EditProfile() {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [image, setImage] = useState(null);
  const [changePassword, setChangePassword] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [googleId, setGoogleId] = useState(null);
  const navigate = useNavigate();
  const { user, token, isAuthenticated, logout, updateProfile } = useAuth();

  const fetchUserProfile = useCallback(async () => {
    try {
      const response = await axios.get(`/users/${user.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const userData = response.data;
      setUsername(userData.username);
      setEmail(userData.email);
      setRole(userData.role);
      setImage(userData.image);
      setGoogleId(userData.googleId);
    } catch (error) {
      console.error("Failed to fetch user profile", error);
    }
  }, [user.id, token]);

  useEffect(() => {
    if (isAuthenticated && user) {
      fetchUserProfile();
    }
  }, [fetchUserProfile, isAuthenticated, user]);

  useEffect(() => {
    const handleProfileUpdated = () => {
      fetchUserProfile();
    };

    window.addEventListener("profileUpdated", handleProfileUpdated);

    return () => {
      window.removeEventListener("profileUpdated", handleProfileUpdated);
    };
  }, [fetchUserProfile]);

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("username", username);
    if (!googleId) {
      formData.append("email", email);
    }
    formData.append("role", role);
    if (image) {
      formData.append("image", image);
    }

    if (changePassword && !googleId) {
      if (newPassword !== confirmNewPassword) {
        Swal.fire("Error", "New passwords do not match", "error");
        return;
      }
      formData.append("oldPassword", oldPassword);
      formData.append("newPassword", newPassword);
    }

    try {
      const response = await axios.put(`/users/profile/${user.id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      const updatedUser = response.data;
      updateProfile(updatedUser);

      if (changePassword && !googleId) {
        Swal.fire({
          title: "Password Changed",
          text: "Password changed successfully. Please log in again.",
          icon: "success",
        }).then(() => {
          logout();
          navigate("/login");
        });
      } else {
        Swal.fire("Success", "Profile updated successfully", "success").then(
          () => {
            const event = new CustomEvent("profileUpdated");
            window.dispatchEvent(event);
          }
        );
      }
    } catch (error) {
      console.error("Failed to update profile", error);
      Swal.fire("Error", "Failed to update profile", "error");
    }
  };

  return (
    <Container className="form-container">
      <h2>Edit Profile</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="username" className="mt-3">
          <Form.Label>Username</Form.Label>
          <Form.Control
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </Form.Group>
        {!googleId && (
          <Form.Group controlId="email" className="mt-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Form.Group>
        )}
        <Form.Group controlId="image" className="mt-3">
          <Form.Label>Profile Picture</Form.Label>
          <Form.Control type="file" onChange={handleImageChange} />
        </Form.Group>

        {!googleId && (
          <Form.Group controlId="changePassword" className="mt-3">
            <Form.Check
              type="checkbox"
              label="Change Password"
              checked={changePassword}
              onChange={(e) => setChangePassword(e.target.checked)}
            />
          </Form.Group>
        )}
        {changePassword && !googleId && (
          <>
            <Form.Group controlId="oldPassword" className="mt-3">
              <Form.Label>Old Password</Form.Label>
              <Form.Control
                type="password"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                required={changePassword}
              />
            </Form.Group>
            <Form.Group controlId="newPassword" className="mt-3">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required={changePassword}
              />
            </Form.Group>
            <Form.Group controlId="confirmNewPassword" className="mt-3">
              <Form.Label>Confirm New Password</Form.Label>
              <Form.Control
                type="password"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                required={changePassword}
              />
            </Form.Group>
          </>
        )}
        <Button type="submit" className="mt-4 action-button" variant="primary">
          Update Profile
        </Button>
      </Form>
    </Container>
  );
}

export default EditProfile;
