import React, { useEffect, useState, useRef, useCallback } from "react";
import axios from "../axiosConfig";
import { Card, Container, Button, Row, Col } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import "../styles/ExamDetails.css";
import { jwtDecode } from "jwt-decode";
import { useAuth } from "../context/AuthContext";

const calculatePoints = (question, answer) => {
  if (question.type === "TIU" || question.type === "TWK") {
    let correctOption = null;
    if (question.pointA === 5) correctOption = "A";
    if (question.pointB === 5) correctOption = "B";
    if (question.pointC === 5) correctOption = "C";
    if (question.pointD === 5) correctOption = "D";
    if (question.pointE === 5) correctOption = "E";

    return answer === correctOption ? 5 : 0;
  } else if (question.type === "TKP") {
    const optionPoints = {
      A: question.pointA,
      B: question.pointB,
      C: question.pointC,
      D: question.pointD,
      E: question.pointE,
    };
    return optionPoints[answer] || 0;
  }
  return 0;
};

function ExamDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user, token } = useAuth();
  const [exam, setExam] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [timeLeft, setTimeLeft] = useState(null);
  const intervalRef = useRef(null);
  const [shouldSaveProgress, setShouldSaveProgress] = useState(false);
  const [finishButtonDisabled, setFinishButtonDisabled] = useState(false);

  const calculateAndSubmitResult = useCallback(
    async (timeUp = false) => {
      if (!exam) return;

      let totalTIU = 0;
      let totalTWK = 0;
      let totalTKP = 0;

      let maxTIU = 0;
      let maxTWK = 0;
      let maxTKP = 0;

      exam.Questions.forEach((question) => {
        const answer = answers[question.id];
        const points = calculatePoints(question, answer);

        if (question.type === "TIU") {
          totalTIU += points;
          maxTIU += 5;
        } else if (question.type === "TWK") {
          totalTWK += points;
          maxTWK += 5;
        } else if (question.type === "TKP") {
          totalTKP += points;
          maxTKP += 5;
        }
      });

      const passingGradeTIU = exam.minPassingTIU;
      const passingGradeTWK = exam.minPassingTWK;
      const passingGradeTKP = exam.minPassingTKP;

      const passTIU = totalTIU >= passingGradeTIU;
      const passTWK = totalTWK >= passingGradeTWK;
      const passTKP = totalTKP >= passingGradeTKP;

      const passed = passTIU && passTWK && passTKP;

      if (!user || !token) {
        Swal.fire(
          "Error",
          "User tidak terautentikasi atau token tidak valid.",
          "error"
        );
        navigate("/login");
        return;
      }

      try {
        const decodedToken = jwtDecode(token);
        const { exp, userId } = decodedToken;
        if (exp * 1000 < Date.now()) {
          throw new Error("Token telah kedaluwarsa");
        }

        const resultData = {
          userId,
          examId: exam.id,
          totalTIU,
          maxTIU,
          totalTWK,
          maxTWK,
          totalTKP,
          maxTKP,
          passed,
          takenAt: new Date().toISOString(),
        };

        await axios.post("/results", resultData);

        const message = passed
          ? "Selamat, Anda lulus ujian!"
          : "Maaf, Anda tidak lulus ujian.";

        Swal.fire({
          icon: passed ? "success" : "error",
          title: passed ? "Lulus" : "Gagal",
          text: `TIU: ${totalTIU}/${maxTIU}, TWK: ${totalTWK}/${maxTWK}, TKP: ${totalTKP}/${maxTKP}. ${message}`,
        });

        localStorage.removeItem("answers");
        localStorage.removeItem("timeLeft");

        navigate("/dashboard/results/list");
      } catch (error) {
        console.error("Error during result submission:", error);
        if (error.message === "Token telah kedaluwarsa") {
          Swal.fire(
            "Error",
            "Sesi Anda telah kedaluwarsa. Silakan masuk kembali.",
            "error"
          );
          navigate("/login");
        } else if (error.response?.status === 403) {
          Swal.fire("Error", "Anda sudah pernah mengambil ujian ini.", "error");
        } else {
          Swal.fire(
            "Error",
            "Gagal menyimpan hasil ujian. Silakan coba lagi.",
            "error"
          );
        }
      }
    },
    [exam, answers, user, token, navigate]
  );

  const fetchExamDetails = useCallback(async () => {
    try {
      const response = await axios.get(`/exams/${id}`);
      setExam(response.data);
      try {
        const progressResponse = await axios.get(
          `/exams/getProgress/${user.id}/${id}`
        );
        const progress = progressResponse.data;
        const parsedAnswers =
          typeof progress.answers === "string"
            ? JSON.parse(progress.answers)
            : progress.answers || {};

        setAnswers(parsedAnswers);
        setTimeLeft(progress.timeLeft || response.data.duration * 60);
        setCurrentQuestionIndex(progress.lastQuestionIndex || 0);
      } catch (progressError) {
        if (progressError.response && progressError.response.status === 404) {
          setAnswers({});
          setTimeLeft(response.data.duration * 60);
        } else {
          console.error("Failed to fetch exam progress:", progressError);
        }
      }
    } catch (error) {
      console.error("Failed to fetch exam details:", error);
    }
  }, [id, user.id]);

  useEffect(() => {
    fetchExamDetails();

    return () => {
      clearInterval(intervalRef.current);
      localStorage.removeItem("ongoingExam");
    };
  }, [fetchExamDetails]);

  const saveProgressToDB = useCallback(
    async (currentTimeLeft, currentAnswers, lastQuestionIndex) => {
      try {
        const progressData = {
          userId: user.id,
          examId: id,
          timeLeft: currentTimeLeft,
          answers: currentAnswers,
        };

        if (lastQuestionIndex > 0) {
          progressData.lastQuestionIndex = lastQuestionIndex;
        }

        await axios.post("/exams/saveProgress", progressData);
      } catch (error) {
        console.error("Failed to save progress:", error);
        if (error.response) {
          Swal.fire("Error", error.response.data.message, "error");
        } else if (error.request) {
          Swal.fire(
            "Error",
            "No response from server, please try again.",
            "error"
          );
        } else {
          Swal.fire("Error", error.message, "error");
        }
      }
    },
    [id, user.id]
  );

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      saveProgressToDB(timeLeft, answers, currentQuestionIndex);
      event.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [timeLeft, answers, currentQuestionIndex, saveProgressToDB]);

  useEffect(() => {
    if (shouldSaveProgress) {
      saveProgressToDB(timeLeft, answers, currentQuestionIndex);
      setShouldSaveProgress(false);
    }
  }, [
    shouldSaveProgress,
    timeLeft,
    answers,
    currentQuestionIndex,
    saveProgressToDB,
  ]);

  useEffect(() => {
    if (timeLeft > 0) {
      intervalRef.current = setInterval(() => {
        setTimeLeft((prevTimeLeft) => {
          const newTimeLeft = prevTimeLeft - 1;
          localStorage.setItem(`timeLeft_${id}`, newTimeLeft);

          if (newTimeLeft % 30 === 0) {
            setShouldSaveProgress(true);
          }
          return newTimeLeft;
        });
      }, 1000);
    } else if (timeLeft === 0) {
      calculateAndSubmitResult(true);
      navigate("/dashboard/results/list");
    }

    return () => clearInterval(intervalRef.current);
  }, [timeLeft, id, calculateAndSubmitResult, navigate]);

  if (!exam) {
    return <div>Loading...</div>;
  }

  const currentQuestion = exam.Questions[currentQuestionIndex];

  const questionStatus = (index) => {
    const questionId = exam.Questions[index].id;
    return answers[questionId] !== undefined ? "btn-success" : "btn-secondary";
  };

  const getButtonClass = (option) => {
    const questionId = exam.Questions[currentQuestionIndex].id;
    return answers[questionId] === option ? "btn-success" : "btn-light";
  };

  const handleAnswerClick = (option) => {
    const questionId = exam.Questions[currentQuestionIndex].id;
    setAnswers((prevAnswers) => {
      const currentAnswer = prevAnswers[questionId];
      let newAnswers = { ...prevAnswers };

      if (currentAnswer === option) {
        delete newAnswers[questionId];
      } else {
        newAnswers[questionId] = option;
      }
      localStorage.setItem(`answers_${id}`, JSON.stringify(newAnswers));
      setShouldSaveProgress(true);
      return newAnswers;
    });
  };

  const handleQuestionNavigation = (index) => {
    if (index < 0 || index >= exam.Questions.length) return;
    setCurrentQuestionIndex(index);
  };

  const handleFinishExamClick = () => {
    if (timeLeft > 0) {
      calculateAndSubmitResult(false);
      setFinishButtonDisabled(true);
    }
  };

  const formatTimeLeft = (timeLeft) => {
    const minutes = Math.floor(timeLeft / 60);
    const seconds = Math.floor(timeLeft % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  return (
    <Container className="mt-4">
      <Row>
        <Col lg={8} md={12}>
          {currentQuestion ? (
            <>
              <Card className="mb-4">
                <Card.Body>
                  <Card.Title>
                    Question number {currentQuestionIndex + 1}
                  </Card.Title>
                  <Card.Text
                    dangerouslySetInnerHTML={{
                      __html: currentQuestion.questionText,
                    }}
                  />
                </Card.Body>
              </Card>
              <Row>
                {["A", "B", "C", "D", "E"].map((option) => (
                  <Col xs={12} sm={6} className="mb-3" key={option}>
                    <Button
                      className={`option-button ${getButtonClass(option)}`}
                      onClick={() => handleAnswerClick(option)}
                    >
                      <strong>{option}.</strong>{" "}
                      <span
                        dangerouslySetInnerHTML={{
                          __html: currentQuestion[`option${option}`],
                        }}
                      />
                    </Button>
                  </Col>
                ))}
              </Row>
              <Row className="mt-4">
                <Col xs={6}>
                  <Button
                    variant="secondary"
                    className="w-100 mb-3"
                    onClick={() =>
                      handleQuestionNavigation(currentQuestionIndex - 1)
                    }
                    disabled={currentQuestionIndex === 0}
                  >
                    Previous
                  </Button>
                </Col>
                <Col xs={6}>
                  <Button
                    variant="secondary"
                    className="w-100 mb-3"
                    onClick={() =>
                      handleQuestionNavigation(currentQuestionIndex + 1)
                    }
                    disabled={
                      currentQuestionIndex === exam.Questions.length - 1
                    }
                  >
                    Next
                  </Button>
                </Col>
              </Row>
            </>
          ) : (
            <div>No questions found</div>
          )}
        </Col>
        <Col lg={4} md={12}>
          <Card className="mb-4">
            <Card.Body>
              <Card.Title>Time Left: {formatTimeLeft(timeLeft)}</Card.Title>
            </Card.Body>
          </Card>
          <div className="question-navigation">
            {exam.Questions.map((question, index) => (
              <Button
                key={index}
                variant="secondary"
                className={`question-button ${questionStatus(index)}`}
                onClick={() => handleQuestionNavigation(index)}
              >
                {index + 1}
              </Button>
            ))}
          </div>
          <Button
            variant="primary"
            className="w-100 mt-4"
            onClick={handleFinishExamClick}
            disabled={finishButtonDisabled}
          >
            Finish Exam
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default ExamDetails;
