import React, { useEffect, useState, useCallback } from "react";
import { Table, Button, Card } from "react-bootstrap";
import axios from "../../axiosConfig";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import DOMPurify from "dompurify";
import ReactPaginate from "react-paginate";
import "../../styles/Admin.css";
import useWindowWidth from "../../hooks/useWindowWidth";

function ListQuestions() {
  const [questions, setQuestions] = useState([]);
  const [exam, setExam] = useState(null);
  const { examId } = useParams();
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  const windowWidth = useWindowWidth();

  const fetchExam = useCallback(async () => {
    try {
      const response = await axios.get(`/exams/${examId}`);
      setExam(response.data);
    } catch (error) {
      console.error("Failed to fetch exam", error);
    }
  }, [examId]);

  const fetchQuestions = useCallback(async () => {
    try {
      const response = await axios.get(`/questions/exam/admin/${examId}`);
      setQuestions(response.data);
    } catch (error) {
      console.error("Failed to fetch questions", error);
    }
  }, [examId]);

  useEffect(() => {
    fetchExam();
    fetchQuestions();
  }, [fetchExam, fetchQuestions]);

  const handleEdit = (id) => {
    navigate(`/dashboard/questions/edit/${id}`);
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`/questions/${id}`);
          fetchQuestions();
          Swal.fire("Deleted!", "The question has been deleted.", "success");
        } catch (error) {
          console.error("Failed to delete question", error);
          Swal.fire("Error", "Failed to delete question", "error");
        }
      }
    });
  };

  const handleAddQuestion = () => {
    navigate(`/dashboard/exams/${examId}/questions/new`);
  };

  const createMarkup = (htmlContent) => {
    return { __html: DOMPurify.sanitize(htmlContent) };
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const offset = currentPage * itemsPerPage;
  const currentItems = questions.slice(offset, offset + itemsPerPage);
  const pageCount = Math.ceil(questions.length / itemsPerPage);

  const isMobile = windowWidth < 768;
  const previousLabel = isMobile ? "<<" : "Previous";
  const nextLabel = isMobile ? ">>" : "Next";

  return (
    <div className="">
      <h2>
        Questions List for{" "}
        {exam ? `${exam.title} - ${exam.Subject?.name}` : "Loading..."}
      </h2>
      <div className="table-responsive">
        <Table striped bordered hover className="d-none d-md-table">
          <thead>
            <tr>
              <th>Id</th>
              <th>Question Text</th>
              <th>Option A Points</th>
              <th>Option B Points</th>
              <th>Option C Points</th>
              <th>Option D Points</th>
              <th>Option E Points</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.length === 0 ? (
              <tr>
                <td colSpan="8" className="text-center">
                  Belum ada soal yang tersedia.
                </td>
              </tr>
            ) : (
              currentItems.map((question) => (
                <tr key={question.id}>
                  <td>{question.id}</td>
                  <td
                    dangerouslySetInnerHTML={createMarkup(
                      question.questionText
                    )}
                  ></td>
                  <td>{question.pointA}</td>
                  <td>{question.pointB}</td>
                  <td>{question.pointC}</td>
                  <td>{question.pointD}</td>
                  <td>
                    {question.pointE !== undefined ? question.pointE : "N/A"}
                  </td>
                  <td>
                    <Button
                      variant="warning"
                      size="sm"
                      onClick={() => handleEdit(question.id)}
                      className="me-2 mb-2"
                    >
                      Edit
                    </Button>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => handleDelete(question.id)}
                      className="mb-2"
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
        <div className="d-md-none">
          {currentItems.length === 0 ? (
            <Card className="text-center">
              <Card.Body>
                <Card.Title>Belum ada soal</Card.Title>
                <Card.Text>
                  Soal akan muncul di sini setelah ditambahkan.
                </Card.Text>
              </Card.Body>
            </Card>
          ) : (
            currentItems.map((question) => (
              <Card key={question.id} className="mb-3">
                <Card.Body>
                  <Card.Title>Question {question.id}</Card.Title>
                  <Card.Text>
                    <strong>Question Text:</strong>{" "}
                    <span
                      dangerouslySetInnerHTML={createMarkup(
                        question.questionText
                      )}
                    ></span>
                  </Card.Text>
                  <Card.Text>
                    <strong>Option A Points:</strong> {question.pointA}
                  </Card.Text>
                  <Card.Text>
                    <strong>Option B Points:</strong> {question.pointB}
                  </Card.Text>
                  <Card.Text>
                    <strong>Option C Points:</strong> {question.pointC}
                  </Card.Text>
                  <Card.Text>
                    <strong>Option D Points:</strong> {question.pointD}
                  </Card.Text>
                  <Card.Text>
                    <strong>Option E Points:</strong>{" "}
                    {question.pointE !== undefined ? question.pointE : "N/A"}
                  </Card.Text>
                  <div className="action-buttons">
                    <Button
                      variant="warning"
                      size="sm"
                      onClick={() => handleEdit(question.id)}
                      className="action-button"
                    >
                      Edit
                    </Button>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => handleDelete(question.id)}
                      className="action-button"
                    >
                      Delete
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            ))
          )}
        </div>
      </div>
      {pageCount > 1 && (
        <ReactPaginate
          previousLabel={previousLabel}
          nextLabel={nextLabel}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination justify-content-center my-4"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
        />
      )}

      <Button
        variant="success"
        onClick={handleAddQuestion}
        className="mt-4 action-button"
      >
        Add Question
      </Button>
    </div>
  );
}

export default ListQuestions;
