import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import "../../styles/ForgotPassword.css";
import { useNavigate } from "react-router-dom";
import config from "../../config";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      Swal.fire({
        icon: "warning",
        title: "Email Tidak Valid",
        text: "Silakan masukkan alamat email yang valid.",
        confirmButtonText: "OK",
      });
      return;
    }

    setLoading(true);

    try {
      await axios.post(`${config.apiBaseUrl}/users/forgot-password`, {
        email,
      });

      Swal.fire({
        icon: "success",
        title: "Email Terkirim",
        text: "Email untuk reset password telah dikirim ke alamat email Anda.",
        confirmButtonText: "OK",
      }).then(() => {
        navigate("/login");
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text:
          error.response?.data?.message ||
          "Terjadi kesalahan saat mengirim email. Silakan coba lagi.",
        confirmButtonText: "OK",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="forgot-password-container">
      <h2>Lupa Password</h2>
      <p>Masukkan email Anda untuk menerima tautan reset password.</p>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn-submit" disabled={loading}>
          {loading ? "Mengirim..." : "Kirim Email Reset"}
        </button>
      </form>
    </div>
  );
};

export default ForgotPassword;
