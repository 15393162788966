import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../axiosConfig";
import axiosImage from "../../axiosConfigImage";
import { Container, Form, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../../styles/Admin.css";

function MaterialForm() {
  const [data, setData] = useState({ title: "", content: "", subjectId: "" });
  const [subjects, setSubjects] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();
  const quillRef = useRef(null);

  // Mengambil data subjects
  const fetchSubjects = useCallback(async () => {
    try {
      const response = await axios.get("/subjects");
      setSubjects(response.data);
    } catch (error) {
      console.error("Failed to fetch subjects", error);
    }
  }, []);

  // Mengambil data material jika dalam mode edit
  const fetchData = useCallback(async () => {
    if (id) {
      try {
        const response = await axios.get(`/materials/${id}`);
        setData(response.data);
      } catch (error) {
        console.error("Failed to fetch material", error);
      }
    }
  }, [id]);

  useEffect(() => {
    fetchSubjects();
    fetchData();
  }, [fetchSubjects, fetchData]);

  // Handle form submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (id) {
        await axios.put(`/materials/${id}`, data);
        Swal.fire("Success", "Material updated successfully", "success");
      } else {
        await axios.post(`/materials`, data);
        Swal.fire("Success", "Material added successfully", "success");
      }
      navigate("/dashboard/materials/list");
    } catch (error) {
      console.error("Failed to submit material", error);
      Swal.fire("Error", "Failed to submit material", "error");
    }
  };

  useEffect(() => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      const toolbar = editor.getModule("toolbar");

      toolbar.addHandler("image", () => {
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();

        input.onchange = async () => {
          const file = input.files[0];
          if (file) {
            const formData = new FormData();
            formData.append("image", file);

            try {
              // Ambil token dari localStorage
              const token = localStorage.getItem("token");
              if (!token) {
                throw new Error("No token found, please login again.");
              }

              // Buat permintaan upload dengan token yang disertakan
              const response = await axiosImage.post("", formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${token}`, // Sertakan token
                },
              });

              // Dapatkan URL gambar yang dikembalikan oleh server
              const imageUrl = response.data.url;
              const range = editor.getSelection();
              editor.insertEmbed(range.index, "image", imageUrl);
            } catch (error) {
              console.error("Error uploading image:", error);
            }
          }
        };
      });
    }
  }, [quillRef]);

  const handleQuillChange = (content) => {
    setData({ ...data, content });
  };

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  // Konfigurasi modul toolbar
  const modules = {
    toolbar: {
      container: [
        [{ header: "1" }, { header: "2" }, { font: [] }],
        [{ size: [] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["link", "image"], // Tombol untuk gambar
        ["clean"],
      ],
    },
  };

  return (
    <Container className="form-container">
      <h2>{id ? "Edit Material" : "Add New Material"}</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formSubjectId" className="mt-3">
          <Form.Label>Subject</Form.Label>
          <Form.Control
            as="select"
            name="subjectId"
            value={data.subjectId}
            onChange={handleChange}
            required
          >
            <option value="">Select a subject</option>
            {subjects.map((subject) => (
              <option key={subject.id} value={subject.id}>
                {subject.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="formTitle" className="mt-3">
          <Form.Label>Title</Form.Label>
          <Form.Control
            type="text"
            name="title"
            value={data.title}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="formContent" className="mt-3">
          <Form.Label>Content</Form.Label>
          <ReactQuill
            ref={quillRef}
            theme="snow"
            value={data.content}
            onChange={handleQuillChange}
            modules={modules}
            placeholder="Write your material content here..."
            className="quill-editor"
          />
        </Form.Group>

        <Button type="submit" className="mt-4 action-button" variant="primary">
          {id ? "Update" : "Add"}
        </Button>
      </Form>
    </Container>
  );
}

export default MaterialForm;
