import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../axiosConfig";
import axiosImage from "../../axiosConfigImage";
import { Container, Form, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../../styles/Admin.css";

const OPTIONS = ["A", "B", "C", "D", "E"];
const POINTS_DEFAULT = ["0", "1", "2", "3", "4", "5"];
const POINTS_TIU_TWK = ["0", "5"];
const POINTS_TKP = ["1", "2", "3", "4", "5"];

const modules = {
  toolbar: {
    container: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote", "code-block"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  },
};

const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "code-block",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
  "color",
  "background",
  "align",
];

function QuestionForm() {
  const { examId, id } = useParams();
  const navigate = useNavigate();
  const quillRef = useRef(null);

  const [data, setData] = useState({
    questionText: "",
    optionA: "",
    optionB: "",
    optionC: "",
    optionD: "",
    optionE: "",
    pointA: "",
    pointB: "",
    pointC: "",
    pointD: "",
    pointE: "",
    type: "",
    discussion: "",
    examId: examId || "",
  });

  const [exams, setExams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [availablePoints, setAvailablePoints] = useState({
    A: [...POINTS_DEFAULT],
    B: [...POINTS_DEFAULT],
    C: [...POINTS_DEFAULT],
    D: [...POINTS_DEFAULT],
    E: [...POINTS_DEFAULT],
  });

  const convertPointsToString = (data) => {
    const updatedData = { ...data };
    OPTIONS.forEach((option) => {
      updatedData[`point${option}`] =
        data[`point${option}`] !== null && data[`point${option}`] !== undefined
          ? String(data[`point${option}`])
          : "";
    });
    return updatedData;
  };

  const fetchData = useCallback(async () => {
    try {
      const response = await axios.get(`/questions/${id}`);
      setData((prevData) => {
        const newData = {
          ...prevData,
          ...convertPointsToString(response.data),
        };
        return newData;
      });
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch question", error);
      Swal.fire("Error", "Failed to fetch question data", "error");
      setLoading(false);
    }
  }, [id]);

  const fetchExams = useCallback(async () => {
    try {
      const response = await axios.get("/exams");
      setExams(response.data);
    } catch (error) {
      console.error("Failed to fetch exams", error);
      Swal.fire("Error", "Failed to fetch exams data", "error");
    }
  }, []);

  useEffect(() => {
    fetchExams();
    if (id) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, [id, fetchData, fetchExams]);

  useEffect(() => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      const toolbar = editor.getModule("toolbar");

      // Logika unggahan gambar hanya berjalan setelah editor dan toolbar siap
      toolbar.addHandler("image", () => {
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();

        input.onchange = async () => {
          const file = input.files[0];
          if (file) {
            const formData = new FormData();
            formData.append("image", file);

            try {
              const token = localStorage.getItem("token");
              if (!token) {
                throw new Error("No token found, please login again.");
              }

              // Unggah gambar menggunakan axiosImage
              const response = await axiosImage.post("", formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${token}`, // Otorisasi dengan token
                },
              });

              // Sisipkan URL gambar ke Quill editor, bukan Base64
              const imageUrl = response.data.url;
              const range = editor.getSelection();
              editor.insertEmbed(range.index, "image", imageUrl);
            } catch (error) {
              console.error("Error uploading image:", error);
              Swal.fire("Error", "Failed to upload image", "error");
            }
          }
        };
      });
    }
  }, [quillRef]);

  const handleTypeChange = (newType) => {
    if (newType === "TIU" || newType === "TWK") {
      setAvailablePoints({
        A: [...POINTS_TIU_TWK],
        B: [...POINTS_TIU_TWK],
        C: [...POINTS_TIU_TWK],
        D: [...POINTS_TIU_TWK],
        E: [...POINTS_TIU_TWK],
      });
      setData((prevData) => ({
        ...prevData,
        type: newType,
        pointA: "",
        pointB: "",
        pointC: "",
        pointD: "",
        pointE: "",
      }));
    } else if (newType === "TKP") {
      setAvailablePoints({
        A: [...POINTS_TKP],
        B: [...POINTS_TKP],
        C: [...POINTS_TKP],
        D: [...POINTS_TKP],
        E: [...POINTS_TKP],
      });
      setData((prevData) => ({
        ...prevData,
        type: newType,
      }));
    } else {
      setAvailablePoints({
        A: [...POINTS_DEFAULT],
        B: [...POINTS_DEFAULT],
        C: [...POINTS_DEFAULT],
        D: [...POINTS_DEFAULT],
        E: [...POINTS_DEFAULT],
      });
      setData((prevData) => ({
        ...prevData,
        type: newType,
      }));
    }
  };

  const updatePointsForTIUorTWK = (newData, option, value) => {
    if (value === "5") {
      OPTIONS.forEach((opt) => {
        if (opt !== option) {
          newData[`point${opt}`] = "0";
        }
      });
    }
  };

  const handlePointChange = (option, value) => {
    setData((prevData) => {
      let newData = { ...prevData, [`point${option}`]: value };

      if (newData.type === "TIU" || newData.type === "TWK") {
        updatePointsForTIUorTWK(newData, option, value);
      }

      if (newData.type === "TKP") {
        const selectedPoints = new Set(
          OPTIONS.map((opt) => newData[`point${opt}`]).filter(Boolean)
        );

        const newAvailablePoints = {};

        OPTIONS.forEach((opt) => {
          newAvailablePoints[opt] = POINTS_TKP.filter(
            (point) =>
              !selectedPoints.has(point) || newData[`point${opt}`] === point
          );
        });

        setAvailablePoints(newAvailablePoints);
      }

      return newData;
    });
  };

  const validatePointsForTIUorTWK = () => {
    const points = ["pointA", "pointB", "pointC", "pointD", "pointE"];
    const hasFive = points.some((p) => data[p] === "5");
    const allZeroOrFive = points.every(
      (p) => data[p] === "0" || data[p] === "5"
    );

    return hasFive && allZeroOrFive;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!data.type) {
      Swal.fire("Error", "Please select a question type", "error");
      return;
    }

    const points = ["pointA", "pointB", "pointC", "pointD", "pointE"];
    const allPointsProvided = points.every((p) => data[p] !== "");

    if (!allPointsProvided) {
      Swal.fire("Error", "Please provide points for all options", "error");
      return;
    }

    if (
      (data.type === "TIU" || data.type === "TWK") &&
      !validatePointsForTIUorTWK()
    ) {
      Swal.fire(
        "Error",
        "For 'TWK' and 'TIU' questions, one option must have a point value of 5 and others 0",
        "error"
      );
      return;
    }

    try {
      if (id) {
        await axios.put(`/questions/${id}`, data);
        Swal.fire("Success", "Question updated successfully", "success");
      } else {
        await axios.post(`/questions/exam/${data.examId}`, data);
        Swal.fire("Success", "Question added successfully", "success");
      }
      navigate(`/dashboard/exams/${data.examId}/questions/list`);
    } catch (error) {
      console.error("Failed to submit question", error);
      Swal.fire("Error", "Failed to submit question", "error");
    }
  };

  const handleQuillChange = (name, content) => {
    setData((prevData) => ({ ...prevData, [name]: content }));
  };

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <Container className="form-container">
      <h2>{id ? "Edit Question" : "Add New Question"}</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formExamId" className="mt-3">
          <Form.Label>Exam</Form.Label>
          <Form.Control
            as="select"
            name="examId"
            value={data.examId}
            onChange={(e) => setData({ ...data, examId: e.target.value })}
            required
          >
            <option value="">Select an exam</option>
            {exams.map((exam) => (
              <option key={exam.id} value={exam.id}>
                {exam.title}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="formType" className="mt-3">
          <Form.Label>Question Type</Form.Label>
          <Form.Control
            as="select"
            name="type"
            value={data.type}
            onChange={(e) => handleTypeChange(e.target.value)}
            required
          >
            <option value="">Select question type</option>
            <option value="TIU">TIU</option>
            <option value="TWK">TWK</option>
            <option value="TKP">TKP</option>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="formQuestionText" className="mt-3">
          <Form.Label>Question Text</Form.Label>
          <ReactQuill
            ref={quillRef}
            theme="snow"
            value={data.questionText}
            onChange={(content) => handleQuillChange("questionText", content)}
            placeholder="Write your question here..."
            className="quill-editor"
            modules={modules}
            formats={formats}
          />
        </Form.Group>

        {OPTIONS.map((option) => (
          <div key={option}>
            <Form.Group controlId={`formOption${option}`} className="mt-3">
              <Form.Label>Option {option}</Form.Label>
              <ReactQuill
                theme="snow"
                value={data[`option${option}`]}
                onChange={(content) =>
                  handleQuillChange(`option${option}`, content)
                }
                placeholder={`Write option ${option} here...`}
                className="quill-editor"
                modules={modules}
                formats={formats}
              />
            </Form.Group>

            <Form.Group controlId={`formPoint${option}`} className="mt-3">
              <Form.Label>Point for Option {option}</Form.Label>
              <Form.Control
                as="select"
                name={`point${option}`}
                value={data[`point${option}`]}
                onChange={(e) => handlePointChange(option, e.target.value)}
                required
              >
                <option value="">Select point</option>
                {availablePoints[option].map((point) => (
                  <option key={point} value={point}>
                    {point}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </div>
        ))}

        <Form.Group controlId="formDiscussion" className="mt-3">
          <Form.Label>Discussion</Form.Label>
          <ReactQuill
            theme="snow"
            value={data.discussion}
            onChange={(content) => handleQuillChange("discussion", content)}
            placeholder="Write discussion here..."
            className="quill-editor"
            modules={modules}
            formats={formats}
          />
        </Form.Group>

        <Button type="submit" className="mt-4 action-button" variant="primary">
          {id ? "Update" : "Add"}
        </Button>
      </Form>
    </Container>
  );
}

export default QuestionForm;
