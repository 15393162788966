import React, { useEffect, useState } from "react";
import axios from "../axiosConfig";
import { Container, Row, Col, Button, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import CustomNavbar from "../components/CustomNavbar";
import CustomFooter from "../components/CustomFooter";
import config from "../config";
import "../styles/Home.css";

const useFetchSubjects = (role) => {
  const [subjects, setSubjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchSubjects = async () => {
      try {
        if (role) {
          const response = await axios.get("/subjects");
          setSubjects(response.data);
        }
      } catch (error) {
        console.error("Failed to fetch subjects", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSubjects();
  }, [role]);

  return { subjects, isLoading };
};

function Home() {
  const { role, user } = useAuth();
  const navigate = useNavigate();
  const { subjects, isLoading } = useFetchSubjects(role);

  const handleStartClick = () => {
    if (subjects.length > 0) {
      navigate(`/subjects/${subjects[0].id}`);
    }
  };

  return (
    <div className="page-container">
      <CustomNavbar />
      <main className="main-content">
        <Container fluid className="h-100">
          <Row className="g-0 flex-fill align-items-center h-100">
            <Col
              xs={12}
              md={6}
              className="image-section d-none d-md-flex h-100"
            >
              <div className="overlay">
                <img
                  src={`${config.imageBaseUrl}uploads/fix.png`}
                  alt="Welcome"
                  className="full-image"
                />
              </div>
            </Col>
            <Col xs={12} md={6} className="text-section h-100">
              <div className="text-content">
                {isLoading ? (
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                ) : (
                  <>
                    <h2>Hallo, {user?.username}</h2>
                    <h4>Selamat Datang di Belajar CPNS Bersama</h4>
                    <p>
                      Mari Kita Belajar Bersama untuk diterima di instansi dan
                      posisi yang kita inginkan.
                    </p>
                    <p>Sudah Siap?</p>
                    <Button
                      onClick={handleStartClick}
                      variant="light"
                      className="start-button"
                    >
                      Ya, ayo Mulai
                    </Button>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </main>
      <CustomFooter />
    </div>
  );
}

export default Home;
