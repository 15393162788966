import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "../../axiosConfig";
import Swal from "sweetalert2";
import { Card, Button } from "react-bootstrap";
import DOMPurify from "dompurify";
import "../../styles/QuestionDetail.css";

const createMarkup = (htmlContent) => {
  const sanitized = DOMPurify.sanitize(htmlContent);
  return { __html: sanitized };
};

const SanitizedContent = React.memo(({ htmlContent }) => {
  const markup = useMemo(() => createMarkup(htmlContent), [htmlContent]);
  return <span dangerouslySetInnerHTML={markup} />;
});

function QuestionDetail() {
  const { examId } = useParams();
  const [questions, setQuestions] = useState([]);
  const [userAnswers, setUserAnswers] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageGroup, setCurrentPageGroup] = useState(0);
  const questionsPerPage = 5;
  const pagesPerGroup = 5;
  const navigate = useNavigate();

  const fetchQuestions = useCallback(async () => {
    try {
      const [questionResponse, answerResponse] = await Promise.all([
        axios.get(`/questions/exam/${examId}`),
        axios.get(`/results/${examId}/answers`),
      ]);
      setQuestions(questionResponse.data);
      const parsedAnswers =
        typeof answerResponse.data.answers === "string"
          ? JSON.parse(answerResponse.data.answers)
          : answerResponse.data.answers || {};

      setUserAnswers(parsedAnswers);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        Swal.fire(
          "Access Denied",
          "You have not completed this exam.",
          "error"
        );
        navigate("/dashboard/results/list");
      } else {
        Swal.fire("Error", "Failed to fetch questions or answers", "error");
        console.error("Failed to fetch questions or answers", error);
      }
    }
  }, [examId, navigate]);

  useEffect(() => {
    fetchQuestions();
  }, [fetchQuestions]);

  const getAnswerColor = (pointsEarned) => {
    const colors = [
      "#FF0000",
      "#FF4500",
      "#FFA500",
      "#FFFF00",
      "#32CD32",
      "#0000FF",
    ];
    return colors[pointsEarned] || "#000000";
  };

  const getAnswerComparison = (question) => {
    const userAnswer = userAnswers[question.id] || "N/A";
    const pointsEarned = question[`point${userAnswer}`] || 0;
    const answerColor = getAnswerColor(pointsEarned);
    return { userAnswer, pointsEarned, answerColor };
  };

  const indexOfLastQuestion = currentPage * questionsPerPage;
  const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
  const currentQuestions = questions.slice(
    indexOfFirstQuestion,
    indexOfLastQuestion
  );

  const totalPages = Math.ceil(questions.length / questionsPerPage);

  const indexOfFirstPageInGroup = currentPageGroup * pagesPerGroup + 1;
  const totalPageGroups = Math.ceil(totalPages / pagesPerGroup);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextGroup = () => {
    if (currentPageGroup < totalPageGroups - 1) {
      setCurrentPageGroup(currentPageGroup + 1);
    }
  };

  const handlePrevGroup = () => {
    if (currentPageGroup > 0) {
      setCurrentPageGroup(currentPageGroup - 1);
    }
  };

  const optionLabels = ["A", "B", "C", "D", "E"];

  return (
    <div>
      <h2>Questions and Explanations</h2>
      {questions.length > 0 ? (
        <div>
          {currentQuestions.map((question, index) => {
            const { userAnswer, pointsEarned, answerColor } =
              getAnswerComparison(question);

            const availableOptions = optionLabels.filter(
              (option) => question[`option${option}`]
            );

            return (
              <Card key={question.id} className="mb-4">
                <Card.Body>
                  <Card.Title>
                    <strong>
                      Question {indexOfFirstQuestion + index + 1}:
                    </strong>{" "}
                    <SanitizedContent htmlContent={question.questionText} />
                  </Card.Title>

                  <div className="options-container">
                    <strong>Options:</strong>
                    {availableOptions.map((option) => (
                      <div
                        key={option}
                        className="d-flex align-items-start mb-2 option-row"
                      >
                        <strong className="me-2">{option}. </strong>
                        <SanitizedContent
                          htmlContent={question[`option${option}`]}
                          className="option-text flex-grow-1"
                        />
                        <span className="ms-2 option-points">
                          (Points: {question[`point${option}`]})
                        </span>
                      </div>
                    ))}
                  </div>

                  <div className="answer-container">
                    <p className="answer-text" style={{ color: answerColor }}>
                      <strong>Your Answer:</strong> {userAnswer} ({pointsEarned}{" "}
                      Points)
                    </p>
                  </div>

                  <hr />

                  <div className="discussion-container">
                    <Card.Footer>
                      <p>
                        <strong>Discussion:</strong>
                      </p>
                      <SanitizedContent htmlContent={question.discussion} />
                    </Card.Footer>
                  </div>
                </Card.Body>
              </Card>
            );
          })}
          <Card className="pagination-card">
            <Card.Body>
              <div className="pagination-container d-flex justify-content-center align-items-center">
                {currentPageGroup > 0 && (
                  <Button
                    variant="light"
                    onClick={handlePrevGroup}
                    className="me-2"
                  >
                    &laquo;
                  </Button>
                )}
                {[...Array(pagesPerGroup).keys()]
                  .map((num) => indexOfFirstPageInGroup + num)
                  .filter((pageNumber) => pageNumber <= totalPages)
                  .map((pageNumber) => (
                    <Button
                      key={pageNumber}
                      variant={pageNumber === currentPage ? "primary" : "light"}
                      onClick={() => handlePageChange(pageNumber)}
                      className="me-2"
                    >
                      {pageNumber}
                    </Button>
                  ))}

                {currentPageGroup < totalPageGroups - 1 && (
                  <Button variant="light" onClick={handleNextGroup}>
                    &raquo;
                  </Button>
                )}
              </div>
            </Card.Body>
          </Card>
        </div>
      ) : (
        <p>No questions found.</p>
      )}
    </div>
  );
}

export default QuestionDetail;
