import React, { useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import "../../styles/ResetPassword.css";
import config from "../../config";

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const { token } = useParams();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post(`${config.apiBaseUrl}/users/reset-password/${token}`, {
        newPassword,
      });

      Swal.fire({
        icon: "success",
        title: "Password berhasil direset",
        text: "Anda akan diarahkan ke halaman login",
        timer: 2000,
        showConfirmButton: false,
      });

      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Gagal mereset password",
        text:
          error.response && error.response.data.message
            ? error.response.data.message
            : "Terjadi kesalahan. Anda akan diarahkan ke halaman login.",
        timer: 2000,
        showConfirmButton: false,
      });

      setTimeout(() => {
        navigate("/login");
      }, 2000);
    }
  };

  return (
    <div className="reset-password-container">
      <h2>Reset Password</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Password Baru</label>
          <input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn-submit">
          Reset Password
        </button>
      </form>
    </div>
  );
};

export default ResetPassword;
