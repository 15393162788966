import React, { useState, useEffect } from "react";
import { Navbar, Dropdown } from "react-bootstrap";
import { useAuth } from "../context/AuthContext";
import config from "../config";
import "../styles/TopBar.css";

function TopBar({ toggleSidebar, sidebarOpen }) {
  const { user, role, logout } = useAuth();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleLogout = () => {
    logout();
  };

  return (
    <Navbar
      bg="primary"
      variant="dark"
      expand="lg"
      className="px-3 d-flex justify-content-between"
    >
      <div
        className="burger"
        onClick={toggleSidebar}
        style={{ cursor: "pointer", fontSize: "2rem" }}
      >
        &#9776;
      </div>
      {!(sidebarOpen && isMobile) && (
        <>
          <Navbar.Brand href="/dashboard" className="ms-3">
            Belajar CPNS Bersama
          </Navbar.Brand>

          <div className="d-flex align-items-center topbar-user-info">
            <div className="text-light me-3">
              {user?.username || "user"} <br />
              <small>{role || "user"}</small>
            </div>

            <Dropdown>
              <Dropdown.Toggle
                as={CustomToggle}
                id="dropdown-custom-components"
              >
                <img
                  src={
                    `${config.imageBaseUrl}uploads/${user?.image}` ||
                    "/default-profile.png"
                  }
                  alt="profile"
                  className="rounded-circle"
                  style={{ width: "40px", height: "40px", cursor: "pointer" }}
                />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="/dashboard/edit-profile">
                  Edit Profile
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </>
      )}
    </Navbar>
  );
}

export default TopBar;

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    style={{ cursor: "pointer" }}
  >
    {children}
  </div>
));
