import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Spinner,
  Card,
} from "react-bootstrap";
import Swal from "sweetalert2";
import { useAuth } from "../../context/AuthContext";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import axios from "../../axiosConfig";

function Login() {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { login: contextLogin } = useAuth();
  const handleGoogleSuccess = async (credentialResponse) => {
    setLoading(true);
    try {
      const tokenId = credentialResponse.credential;
      const res = await axios.post("/users/auth/google", { tokenId });

      const data = res.data;

      if (data && data.token) {
        localStorage.setItem("token", data.token);
        await Swal.fire({
          title: "Login Successful",
          text: `Welcome ${data.user.username}`,
          icon: "success",
          confirmButtonText: "OK",
          background: "#f8f9fa",
        });
        window.location.href = "/";
      } else {
        console.error("Login failed: No token received", data);
        await Swal.fire({
          title: "Login Failed",
          text: "Unable to login with Google. Please try again.",
          icon: "error",
          confirmButtonText: "OK",
          background: "#f8f9fa",
        });
      }
    } catch (error) {
      console.error("Google login failed", error);
      await Swal.fire({
        title: "Login Failed",
        text:
          error.response?.data?.message ||
          "An error occurred while logging in with Google.",
        icon: "error",
        confirmButtonText: "OK",
        background: "#f8f9fa",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleFailure = (error) => {
    console.error("Google login failed", error);
    Swal.fire({
      title: "Login Failed",
      text: "Google login was not successful. Please try again.",
      icon: "error",
      confirmButtonText: "OK",
      background: "#f8f9fa",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await contextLogin(login, password);

      if (response) {
        const role = response.user.role;
        await Swal.fire({
          title: "Login Successful",
          text: `Welcome ${response.user.username}`,
          icon: "success",
          confirmButtonText: "OK",
          background: "#f8f9fa",
        });

        if (role === "student") {
          navigate("/");
        } else {
          navigate("/dashboard");
        }
      } else {
        console.error("Login failed: Invalid response data", response);
        await Swal.fire({
          title: "Login Failed",
          text: "An error occurred. Please try again.",
          icon: "error",
          confirmButtonText: "OK",
          background: "#f8f9fa",
        });
      }
    } catch (error) {
      console.error("Login failed", error);
      await Swal.fire({
        title: "Login Failed",
        text:
          error.response?.data?.message ||
          "Please check your credentials and try again.",
        icon: "error",
        confirmButtonText: "OK",
        background: "#f8f9fa",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <div
        style={{
          backgroundColor: "#f0f4f8",
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={6} lg={4}>
              <Card
                className="p-4 shadow"
                style={{
                  borderRadius: "10px",
                  backgroundColor: "#ffffff",
                }}
              >
                <h2 className="text-center mb-4" style={{ color: "#6c757d" }}>
                  Belajar CPNS Bersama
                </h2>
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="formLogin">
                    <Form.Label>Email or Username</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter email or username"
                      value={login}
                      onChange={(e) => setLogin(e.target.value)}
                      required
                      autoFocus
                    />
                  </Form.Group>

                  <Form.Group controlId="formPassword" className="mt-3">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Enter password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </Form.Group>

                  <Button
                    type="submit"
                    className="w-100 mt-4"
                    variant="primary"
                    disabled={loading}
                  >
                    {loading ? (
                      <>
                        <Spinner animation="border" size="sm" /> Logging in...
                      </>
                    ) : (
                      "Login"
                    )}
                  </Button>

                  <div className="d-flex justify-content-between mt-3">
                    <a href="/forgot-password" className="text-decoration-none">
                      Forgot Password?
                    </a>
                    <a href="/register" className="text-decoration-none">
                      Sign Up
                    </a>
                  </div>
                </Form>

                <hr className="my-4" />
                <GoogleLogin
                  onSuccess={handleGoogleSuccess}
                  onError={handleGoogleFailure}
                  className="w-100"
                />
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </GoogleOAuthProvider>
  );
}

export default Login;
