import React, { useEffect, useState } from "react";
import axios from "../axiosConfig";
import { useAuth } from "../context/AuthContext";
import { Navbar, Nav, Container, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import config from "../config";

function CustomNavbar() {
  const [subjects, setSubjects] = useState([]);
  const navigate = useNavigate();
  const { role } = useAuth();

  useEffect(() => {
    const fetchSubjects = async () => {
      try {
        if (role) {
          const response = await axios.get("/subjects");
          setSubjects(response.data);
        }
      } catch (error) {
        console.error("Failed to fetch subjects", error);
      }
    };

    fetchSubjects();
  }, [role]);

  const handleStartClick = () => {
    if (subjects.length > 0) {
      navigate(`/subjects/${subjects[0].id}`);
    }
  };

  return (
    <Navbar
      expand="lg"
      style={{
        background: "linear-gradient(90deg, #ff7e5f, #feb47b)",
        position: "sticky",
        top: "0",
        zIndex: "1000",
      }}
    >
      <Container>
        <Navbar.Brand
          href="/"
          style={{ display: "flex", alignItems: "center" }}
        >
          <img
            src={`${config.imageBaseUrl}uploads/logo.png`}
            alt="Belajar CPNS Bersama Logo"
            height="50px"
            style={{ marginRight: "10px" }}
          />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          style={{ borderColor: "#fff" }}
        />

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav
            className="ms-auto"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Nav.Link href="/" style={{ color: "#fff", marginRight: "15px" }}>
              Home
            </Nav.Link>
            <Nav.Link
              onClick={handleStartClick}
              style={{ color: "#fff", marginRight: "15px" }}
            >
              Materi dan Tryout
            </Nav.Link>
            <Button
              variant="outline-light"
              onClick={() => navigate("/dashboard")}
              style={{
                color: "#fff",
                borderColor: "#fff",
                marginLeft: "10px",
                transition: "background-color 0.3s, color 0.3s",
              }}
              onMouseOver={(e) => {
                e.target.style.backgroundColor = "#fff";
                e.target.style.color = "#ff7e5f";
              }}
              onMouseOut={(e) => {
                e.target.style.backgroundColor = "transparent";
                e.target.style.color = "#fff";
              }}
            >
              Dashboard
            </Button>
          </Nav>
        </Navbar.Collapse>

        <div
          style={{
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            color: "#fff",
            fontSize: "1.5rem",
            fontWeight: "bold",
            whiteSpace: "nowrap",
          }}
        >
          Belajar CPNS Bersama
        </div>
      </Container>
    </Navbar>
  );
}

export default CustomNavbar;
