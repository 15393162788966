import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "./components/Auth/Login";
import Register from "./components/Auth/Register";
import Dashboard from "./components/Dashboard";
import ForgotPassword from "./components/Auth/ForgotPassword";
import ResetPassword from "./components/Auth/ResetPassword";
import SubjectForm from "./components/Subjects/SubjectForm";
import ListSubjects from "./components/Subjects/ListSubjects";
import MaterialForm from "./components/Materials/MaterialForm";
import ListMaterials from "./components/Materials/ListMaterials";
import ExamForm from "./components/Exams/ExamForm";
import ListExams from "./components/Exams/ListExams";
import QuestionForm from "./components/Questions/QuestionForm";
import ListQuestions from "./components/Questions/ListQuestions";
import QuestionDetail from "./components/Questions/QuestionDetail";
import ListResults from "./components/Results/ListResults";
import Home from "./components/Home";
import SubjectDetails from "./components/SubjectDetails";
import MaterialDetails from "./components/MaterialDetails";
import ExamDetails from "./components/ExamDetails";
import AddUser from "./components/Users/AddUser";
import ListUsers from "./components/Users/ListUsers";
import EditProfile from "./components/Users/EditProfile";
import EditUser from "./components/Users/EditUser";
import PrivateRoute from "./components/PrivateRoute";
import PublicRoute from "./components/PublicRoute";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route element={<PublicRoute />}>
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route path="/" element={<Home />} />
          <Route path="/subjects/:id" element={<SubjectDetails />} />
          <Route path="/materials/:id" element={<MaterialDetails />} />
          <Route path="/exams/:id" element={<ExamDetails />} />
          <Route path="/dashboard" element={<Dashboard />}>
            <Route path="subjects/new" element={<SubjectForm />} />
            <Route path="subjects/list" element={<ListSubjects />} />
            <Route path="subjects/edit/:id" element={<SubjectForm />} />
            <Route path="materials/new" element={<MaterialForm />} />
            <Route path="materials/list" element={<ListMaterials />} />
            <Route path="materials/edit/:id" element={<MaterialForm />} />
            <Route path="exams/new" element={<ExamForm />} />
            <Route path="exams/list" element={<ListExams />} />
            <Route path="exams/edit/:id" element={<ExamForm />} />
            <Route
              path="exams/:examId/questions/new"
              element={<QuestionForm />}
            />
            <Route
              path="exams/:examId/questions/list"
              element={<ListQuestions />}
            />
            <Route
              path="exams/:examId/questions/detail"
              element={<QuestionDetail />}
            />
            <Route path="questions/edit/:id" element={<QuestionForm />} />
            <Route path="results/list" element={<ListResults />} />
            <Route path="users/list" element={<ListUsers />} />
            <Route path="users/new" element={<AddUser />} />
            <Route path="users/edit/:id" element={<EditUser />} />
            <Route path="edit-profile" element={<EditProfile />} />
          </Route>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
