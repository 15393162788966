import React, { useState, useEffect } from "react";
import { FaSun, FaMoon } from "react-icons/fa";
import "../styles/welcome.css";
import { useAuth } from "../context/AuthContext";

function DashboardWelcome() {
  const [currentTime, setCurrentTime] = useState(new Date());
  const { user } = useAuth();

  useEffect(() => {
    const timerId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(timerId);
  }, []);

  const formatDate = currentTime.toLocaleDateString("en-GB", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const formatTime = currentTime.toLocaleTimeString("en-GB");

  const getGreeting = () => {
    const hour = currentTime.getHours();
    if (hour >= 5 && hour < 12) {
      return "Good morning";
    } else if (hour >= 12 && hour < 18) {
      return "Good afternoon";
    } else if (hour >= 18 && hour < 21) {
      return "Good evening";
    } else {
      return "Good night";
    }
  };

  const isDayTime = currentTime.getHours() >= 5 && currentTime.getHours() < 18;

  return (
    <div className="dashboard-card">
      <div className="greeting-section">
        {isDayTime ? (
          <FaSun className="weather-icon" />
        ) : (
          <FaMoon className="weather-icon" />
        )}
        <div>
          <p className="greeting-text">
            Hello, {getGreeting()}, {user?.username}
          </p>
          <p className="date-time">
            {formatDate} <br />
            {formatTime}
          </p>
        </div>
      </div>
    </div>
  );
}

export default DashboardWelcome;
